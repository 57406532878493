
import {Component, Vue} from 'vue-property-decorator';
import DocumentUpload from "../components/DocumentUpload.vue";
import SubmissionService from "../service/submission-service";
import CustomPageTitle from "../components/CustomPageTitle.vue";
import FileUploaderInfoBox from "../components/FIleUploaderInfoBox.vue";
import {ExistingSubmission, SubmissionControllerApi, UploadedDocumentMetadata} from "../client";
import http from "../plugins/http";
import {formatISODateTime} from '../filters/filters';
import SelectItem from "../common/dto/vuetify/select-item";

@Component({
    components: {
        CustomPageTitle,
        DocumentUpload,
        FileUploaderInfoBox
    },
    filters: {
        formatISODateTime,
    }
})
export default class DocumentSubmission extends Vue {

    public submissionService = new SubmissionService();
    public submissionControllerApi = new SubmissionControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);

    private file: File | null = null;
    private acceptableMimeTypes = []

    private sender = '';
    private senderList = Array<string>();
    private recipientList: SelectItem[] = [];
    private existingSubmissions: ExistingSubmission[] = [];
    private recipient = '';
    private subject = '';
    private subjectErrorMessage = '';
    private sendMail = false;
    private lastSubmitSuccessful: boolean | null = null;

    get isLastSubmitSuccessful() {
        return this.lastSubmitSuccessful;
    }

    get subjectTooLong() {
        if (this.subject.length > 255) {
            this.subjectErrorMessage = this.$t('document-submission.too-long-subject').toString();
            return true;
        } else {
            this.subjectErrorMessage = '';
            return false;
        }
    }

    public mounted() {
        this.loadSenderEmailAddress();
        this.loadRecipientEmailAddress();
    }

    get isSendDisabled() {
        return !this.file || !this.sender || !this.recipient || !this.subject || this.subjectTooLong;
    }

    clearSubmissionFileRelatedData() {
        this.file = null;
        this.recipient = "";
        this.subject = "";
        this.lastSubmitSuccessful = null;
        this.existingSubmissions = [];
    }

    private loadSenderEmailAddress() {
        this.submissionControllerApi.listActiveEmailAddress()
            .then(response => {
                this.senderList = response.data.emailAddresses;
                this.sender = response.data.emailAddresses[0];
            });
    }

    private loadRecipientEmailAddress() {
        this.submissionControllerApi.listCourts()
            .then(response => {
                this.recipientList = response.data.registryCourts.map(registryCourt => {
                    return {text: registryCourt, value: registryCourt, disabled: false} as SelectItem;
                });
            });
    }

    private isRecipientDisabled(registryCourt: SelectItem): boolean {
        return !!this.existingSubmissions.find(submission => submission.recipient == registryCourt.value);
    }

    private submit() {
        const formData = new FormData();

        formData.append("file", this.file as Blob);
        formData.append("sender", this.sender);
        formData.append("recipient", this.recipient);
        formData.append("subject", this.subject);
        formData.append("sendMail", this.sendMail.toString());

        this.lastSubmitSuccessful = null;

        this.submissionService.submit(formData).then(
            () => {
                // HTTP post was successful.
                this.clearSubmissionData();
                window.scrollTo(0, 0);
                this.lastSubmitSuccessful = true;
            },
            () => {
                // HTTP post was failed.
                this.lastSubmitSuccessful = false;
            });
    }

    private clearSubmissionData() {
        this.clearSubmissionFileRelatedData();
        this.sendMail = false;
    }

    private updateFile(value: File) {
        this.file = value;
        this.getFileMetaData(value);
    }

    private getFileMetaData(value: File) {
        const formData = new FormData();
        formData.append("file", value as Blob);

        this.submissionService.getMetadata(formData)
            .then(response => {
                this.existingSubmissions = response.data.existingSubmissions;
                this.updateMetadata(response.data.uploadedDocumentMetadata);
            })
            .catch(() => {
                this.clearSubmissionFileRelatedData();
            });
    }

    private updateMetadata(value: UploadedDocumentMetadata) {
        this.subject = value.subject;
        this.recipient = (this.isRecipientDisabled({ value: value.recipient }) ? '' : value.recipient) || '';
    }
}
