import http from "../plugins/http";
import {FileDownloadService} from "./file-download-service";
import {DocumentProcessingResponse, FileResponse} from "../client";
import axios, {AxiosPromise} from "axios";

export default class SubmissionService {
    private fileDownloadService = new FileDownloadService();

    private readonly contentType = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    public getMetadata(formData: FormData): AxiosPromise<DocumentProcessingResponse> {
        return http.post<DocumentProcessingResponse>(`/submissions/metadata`, formData, this.contentType)
    }

    public submit(formData: FormData) {
        return http.post(`/submissions`, formData, this.contentType)
    }

    public submitCreationDocumentDownloadPdf(xmlFile: string, fileName: string) {
        const trimmedFileName = fileName.substring(0, fileName.length - 4)

        axios({
            method: 'post',
            url: http.defaults.baseURL + "/api/creationDocument",
            data: xmlFile,
            responseType: 'arraybuffer',
            headers: {
                "Content-Type": "application/xml"
            }
        }).then(
            (response) => {
                const blob = new Blob([response.data], {type: "application/pdf;charset=uts-8"});
                const link = document.createElement('a');
                const objectURL = URL.createObjectURL(blob);
                link.href = objectURL;
                link.download = `${trimmedFileName}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.open(objectURL);
            }
        ).catch(reason => {
            if (reason.response.status === 400) {
                window.alert("A beküldött xml fájl sémája nem megfelelő.");
            } else {
                window.alert("Hiba a fájl feldolgozása során.");
            }
        });
    }

    public downloadFile(id: number) {
        http.get<FileResponse>(`/submissions/${id}/download`).then(
            (response) => {
                this.fileDownloadService.downloadFile(response.data);
            }
        )
    }
}
